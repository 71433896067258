@import 'bootstrap.min';
@import 'font-awesome.min';
@import 'et-line-fonts';
@import 'animate';
@import 'hover';
@import 'magnific-popup';
@import 'owl.carousel';
@import 'owl.transitions';

@import 'style';



#flatimg {

}

#flat-carousel .item{
  //margin: 3px;
}
#flat-carousel .item img{
  display: block;
  width: 100%;
  height: auto;
}

#flat-carousel .owl-controls{
  margin-top: 0;
  position: absolute;
  bottom:20px;
  right: 50%;
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(50%);
  -o-transform: translateX(50%);
  transform: translateX(50%);

}

.reviews .review > div {
  background-color: rgba(255,255,255,0.9);
}
